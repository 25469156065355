import React from 'react';
import PropTypes from 'prop-types';
import MenuBar from '../partials/global/MenuBar';
import PDFWrap from '../partials/global/PDFWrap';

function Allergy(props) {
  const {
    pageContext: {
      pdfUrl,
    },
  } = props;
  return (
    <section className="full-height-section">
      <MenuBar />
      <div className="section-content">
        <PDFWrap pdfUrl={pdfUrl} />
      </div>
    </section>
  );
}

Allergy.propTypes = {
  pageContext: PropTypes.shape({
    pdfUrl: PropTypes.string.isRequired,
  }).isRequired,
};
export default Allergy;
